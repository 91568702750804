import {Injectable} from "@angular/core";
import {ReportingInfo} from "../models/reporting.model";
import {LocalSettingsService} from "./local-settings.service";
import {GroupService} from "./group.service";
import {UserService} from "./user.service";
import {
  ApplicationSettings,
  Group,
  GroupDatabasesInfo,
  HealthcareParty,
  PaginatedListAccessLog,
  User
} from "@icure/api";
import * as XLSX from 'xlsx';
import {getDbSize} from "../utils/tools-utils";
import {DesktopService} from "./desktop.service";
import {ASSISTANT_INFO, LICENCE_KEY} from "../../app/private/database/database-user/database-user.component";
import {HcpService} from "./hcp.service";
import {FetchResponse} from "./couch-db.service";
import {AvailableRelease, GroupRelease, GroupReleaseInfos} from "../models/group.model";
import {RolloutTypeEnum, UpdateTypeEnum} from "../../app/private/database/database-group/database-group.component";
import {AccessLogService} from "./access-log.service";
import {AccessLog} from "@icure/api/icc-api/model/AccessLog";
import {AssistantInfos} from "../models/user.model";
import {ApplicationSettingsService, CustomerInvoicingInfos, InvoicingAddress} from "./application-settings.service";
import * as _ from "lodash";

@Injectable({
  providedIn: 'root',
})
export class ReportingService {

    constructor(
      private localSettingsService: LocalSettingsService,
      private groupService: GroupService,
      private userService: UserService,
      private desktopService: DesktopService,
      private hcpService: HcpService,
      private accessLogService: AccessLogService,
      private applicationSettingsService: ApplicationSettingsService
    ) {
    }

    public async launchReporting(reportinInfo: ReportingInfo){
      switch (reportinInfo.name){
        case 'getAllUsersByGroupId': await this.getAllUsersByGroupId(); break;
        case 'getAllUsersInfosByGroupId': await this.getAllUsersInfosByGroupId(); break;
        case 'getLicenceOfAllUsersByGroupId': await this.getLicenceOfAllUsersByGroupId(); break;
        case 'getActiveDBFromDate': await this.getActiveDbFromDate(); break;
        case 'getAssitantInvoicingValidation': await this.getAssitantInvoicingValidation(); break;
        case 'getDatabaseOriginSetting': await this.getDatabaseOriginSetting(); break;
        case 'getConfigVersionByGroupId': await this.getConfigVersionByGroupId(); break;
        default: console.log('reporting not found'); break;
      }
    }

    private async getAllUsersByGroupId(){
      let cpt = 0;
      const listOfGroups = this.localSettingsService.listOfGroups?.length ? this.localSettingsService.listOfGroups : await this.groupService.getAllGroups();
      const activeGroupsResp = await this.desktopService.listGroups();
      const activeGroups = activeGroupsResp?.status?.status === 200 ? activeGroupsResp?.content : [];
      const listOfUserByGroupId: any[] = [];
      const getAvailableReleaseVersionsResponse: FetchResponse = await this.desktopService?.getAvailableReleaseVersions();
      const availableReleaseVersions = getAvailableReleaseVersionsResponse?.content;
      const allGroupsReleaseInfos = await this.desktopService.getReleaseInfos(availableReleaseVersions?.filter((version: any) => version?.startsWith('3')));

      for(let group of listOfGroups){
        cpt++;
        let dbSize = {
          base: '',
          healthdata: '',
          patient: '',
        }
        const groupStorageInfos: GroupDatabasesInfo | null = await this.groupService.getGroupStorageInfos(group.id!!).catch(e => null);
        groupStorageInfos?.databasesInfo?.map(infos => {
          const splitedId = infos?.id?.split('-');
          const dbType = splitedId?.[splitedId.length -1];
          // @ts-ignore
          dbSize[dbType] = getDbSize(infos?.fileSize);
        })
        const listOfUser: Array<User> = await this.userService.getUserInGroup(group.id!!, null, null, 500).catch(e => null);
        const activeUsers = listOfUser?.filter(user => user.status === User.StatusEnum.ACTIVE && !user?.patientId && !!user?.email);
        listOfUserByGroupId.push({
          groupId: group.id,
          email: activeUsers?.map(user => user.email).join(', '),
          baseSize: dbSize?.base,
          healthDataSize: dbSize?.healthdata,
          patientSize: dbSize?.patient,
          cluster:  group.servers?.[0]?.replace('https://', '')?.replace('.icure.cloud', '')?.replace(':444', ''),
          activeDb: activeGroups?.includes(group.id!!)?.toString(),
          release3xx: !!allGroupsReleaseInfos?.filter(gri => gri?.groupIds?.find(gid => gid?.groupId === group?.id))?.length
        });
        console.log(cpt+'/'+listOfGroups.length);
      }

      var worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(listOfUserByGroupId);
      const workbook = XLSX.utils.book_new();
      const sheetName: string = `${new Date().getTime().toString()}getAllUsersByGroupId.xlsx`;
      XLSX.utils.book_append_sheet(workbook, worksheet, "getAllUsersByGroupId");
      XLSX.writeFile(workbook, sheetName);
    }

  private async getAllUsersInfosByGroupId(){
    let cpt = 0;
    const activeGroupsResp = await this.desktopService.listGroups();
    const activeGroups = activeGroupsResp?.status?.status === 200 ? activeGroupsResp?.content : [];
    const listOfUserByGroupId: any[] = [];

    for(let group of activeGroups){
      cpt++;
      const listOfUser: Array<User> = await this.userService.getUserInGroup(group, null, null, 500).catch(e => null);
      const activeUsers = listOfUser?.filter(user => user.status === User.StatusEnum.ACTIVE && !user?.patientId && !!user?.email);
      const listOfHcps: HealthcareParty[] | null = await this.hcpService.getHcpsInGroup(group, listOfUser?.map(usr => usr?.healthcarePartyId!!)).catch(e => null);

      activeUsers!!?.map(user => {
        const hcp = listOfHcps?.find(hcp => hcp?.id === user?.healthcarePartyId);

        listOfUserByGroupId.push({
          groupId: group,
          firstName: hcp?.firstName || '',
          lastName: hcp?.lastName || '',
          inami: hcp?.nihii || '',
          speciality: hcp?.speciality || '',
          ssin: hcp?.ssin || '',
          active: user?.status === User.StatusEnum.ACTIVE ? 'Actif' : 'Inactif',
        });
      })
      console.log(cpt+'/'+activeGroups.length);
    }

    var worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(listOfUserByGroupId);
    const workbook = XLSX.utils.book_new();
    const sheetName: string = `${new Date().getTime().toString()}_getDatabaseOriginSetting.xlsx`;
    XLSX.utils.book_append_sheet(workbook, worksheet, "getDatabaseOriginSetting");
    XLSX.writeFile(workbook, sheetName);
  }

    public async getDatabaseOriginSetting(){
      let cpt = 0;
      const listOfGroups = this.localSettingsService.listOfGroups?.length ? this.localSettingsService.listOfGroups : await this.groupService.getAllGroups();
      const listOfGroupIds: any[] = [];
      for(let group of listOfGroups){
        cpt++;
        const settings: FetchResponse | void = await this.desktopService.getAllApplicationSettings(group.id!!, group.password!!, group.servers?.[0]?.replace('https://', '')?.replace('.icure.cloud', '')?.replace(':444', '')!!).catch(e => console.warn('Cannot find application settings', e))
        // @ts-ignore
        if(settings?.status?.status === 200){
          // @ts-ignore
          const databaseOriginSetting = settings?.content?.find(setting => setting?._id === 'APPSETTINGS|DATABASE-ORIGIN|1.0.0') || {};
          const parsedDatabaseOriginSetting = this.applicationSettingsService.getDatabaseOriginInfos(databaseOriginSetting);
          listOfGroupIds.push({
            groupId: group.id,
            databaseOrigin: parsedDatabaseOriginSetting?.databaseOrigin,
            iCureMacDatabase: parsedDatabaseOriginSetting?.iCureMacDatabase
          })
        }else{
          listOfGroupIds.push({
            groupId: group.id,
            databaseOrigin: 'Error when parsing settings, no result to show',
            iCureMacDatabase: false
          })
        }
        console.log(cpt+'/'+listOfGroups.length);
      }

      var worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(listOfGroupIds);
      const workbook = XLSX.utils.book_new();
      const sheetName: string = `${new Date().getTime().toString()}_getDatabaseOriginSetting.xlsx`;
      XLSX.utils.book_append_sheet(workbook, worksheet, "getDatabaseOriginSetting");
      XLSX.writeFile(workbook, sheetName);
    }

    public async getLicenceOfAllUsersByGroupId(){
      let cpt = 0;
      const listOfGroups = this.localSettingsService.listOfGroups?.length ? this.localSettingsService.listOfGroups : await this.groupService.getAllGroups();
      const listOfUserByGroupId: any[] = [];
      for(let group of listOfGroups){
        cpt++;
        const listOfUser: Array<User> = await this.userService.getUserInGroup(group.id!!, null, null, 500).catch(e => null);
        const activeUsersWithLicence = listOfUser?.filter(user => user.status === User.StatusEnum.ACTIVE && !user?.patientId);
        const listOfHcps: HealthcareParty[] | null = await this.hcpService.getHcpsInGroup(group.id!!, activeUsersWithLicence?.map(usr => usr?.healthcarePartyId!!)).catch(e => null);
        const settings: FetchResponse | void = await this.desktopService.getAllApplicationSettings(group.id!!, group.password!!, group.servers?.[0]?.replace('https://', '')?.replace('.icure.cloud', '')?.replace(':444', '')!!).catch(e => console.warn('Cannot find application settings', e))

        if(activeUsersWithLicence?.length){
          for(let user of activeUsersWithLicence){
            const hcp = listOfHcps?.find(hcp => hcp?.id === user?.healthcarePartyId);
            const licenceInfos = this.userService.getLicenceInfos(user?.properties?.find(prop => prop?.type?.identifier === LICENCE_KEY)!!);
            const dateOfBirth = hcp?.ssin ? hcp?.ssin?.substring(0, 6)?.replace(/^(\d{2})(\d{2})(\d{2})$/, '$3/$2/$1') : '-';
            // @ts-ignore
            if(settings?.status?.status === 200) {
              // @ts-ignore
              const customerInvoicingInfos: CustomerInvoicingInfos = this.applicationSettingsService.parseCustomerInvoicingSetting(settings?.content?.find(setting => setting?._id === 'APPSETTINGS|CUSTOMER-INVOICING|1.0.0'));
              const invoicingInfosId = customerInvoicingInfos?.invoicingAddressAndUserRelationships?.addressIdByUserId?.[user?.id!!];
              const userInvoicingInfos: InvoicingAddress = customerInvoicingInfos?.invoicingAddresses?.[invoicingInfosId];
              listOfUserByGroupId.push({
                groupId: group.id,
                email: user?.email,
                licenceStartDate: licenceInfos?.startDate || '-',
                licenceEndDate: licenceInfos?.endDate || '-',
                licenceType: licenceInfos?.licenceType || '-',
                firstName: hcp?.firstName || '',
                lastName: hcp?.lastName || '',
                inami: hcp?.nihii || '',
                speciality: hcp?.speciality || '',
                ssin: hcp?.ssin || '',
                dateOfBirth: dateOfBirth || '',
                city: userInvoicingInfos?.city || '',
                country: userInvoicingInfos?.country || '',
                dataSource: userInvoicingInfos?.dataSource || '',
                emailAddress: userInvoicingInfos?.emailAddress || '',
                externalId: userInvoicingInfos?.externalId || '',
                name: userInvoicingInfos?.name || '',
                phoneNumber: userInvoicingInfos?.phoneNumber || '',
                postalCode: userInvoicingInfos?.postalCode || '',
                street: userInvoicingInfos?.street || '',
                vat: userInvoicingInfos?.vat || '',
                modifiedOn: userInvoicingInfos?.modifiedOn ? (() => {
                  const date = new Date(userInvoicingInfos.modifiedOn);
                  const day = String(date.getDate()).padStart(2, '0');
                  const month = String(date.getMonth() + 1).padStart(2, '0');
                  const year = date.getFullYear();
                  return `${day}/${month}/${year}`;
                })() : ''
              });
            }
          }
        }
        console.log(cpt+'/'+listOfGroups.length);
      }

      var worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(listOfUserByGroupId);
      const workbook = XLSX.utils.book_new();
      const sheetName: string = `${new Date().getTime().toString()}_getLicenceOfAllUsersByGroupId.xlsx`;
      XLSX.utils.book_append_sheet(workbook, worksheet, "getLicenceOfAllUsersByGroupId");
      XLSX.writeFile(workbook, sheetName);
    }

    public async getAssitantInvoicingValidation(){
      let cpt = 0;
      const listOfGroups = this.localSettingsService.listOfGroups?.length ? this.localSettingsService.listOfGroups : await this.groupService.getAllGroups();
      const listOfUserByGroupId: any[] = [];
      for(let group of listOfGroups){
        cpt++;
        const listOfUser: Array<User> = await this.userService.getUserInGroup(group.id!!, null, null, 500).catch(e => null);
        const listOfHcps: HealthcareParty[] | null = await this.hcpService.getHcpsInGroup(group.id!!, listOfUser?.map(usr => usr?.healthcarePartyId!!)!!).catch(e => null);

        const userWithAssistantInfo = listOfUser?.filter(user => user.status === User.StatusEnum.ACTIVE && !user?.patientId && user?.properties?.find(prop => prop?.type?.identifier === 'be.medispring.preferences.assistantInvoicingInformation'));
        const settings: FetchResponse | void = await this.desktopService.getAllApplicationSettings(group.id!!, group.password!!, group.servers?.[0]?.replace('https://', '')?.replace('.icure.cloud', '')?.replace(':444', '')!!).catch(e => console.warn('Cannot find application settings', e))

        if(userWithAssistantInfo?.length){
          for(let user of userWithAssistantInfo){
            const assitantHcp = listOfHcps?.find(hcp => hcp?.id === user?.healthcarePartyId);
            // @ts-ignore
            const customerInvoicingInfos: CustomerInvoicingInfos = this.applicationSettingsService.parseCustomerInvoicingSetting(settings?.content?.find(setting => setting?._id === 'APPSETTINGS|CUSTOMER-INVOICING|1.0.0'));

            // @ts-ignore
            const assistantInfos: AssistantInfos = this.userService.getAssistantInfos(user?.properties?.find(prop => prop?.type?.identifier === ASSISTANT_INFO));
            const nextSupervisorHcp: HealthcareParty | undefined = listOfHcps?.find(hcp => hcp?.id === assistantInfos?.nextSupervisorId);
            const currentSupervisorHcp: HealthcareParty | undefined = listOfHcps?.find(hcp => hcp?.id === assitantHcp?.supervisorId);
            const currentSupervisorInvoicingInfosId = customerInvoicingInfos?.invoicingAddressAndUserRelationships?.addressIdByUserId?.[listOfUser?.find(user => user?.healthcarePartyId === currentSupervisorHcp?.id)?.id!!];
            const currentSupervisorInvoicingInfos: InvoicingAddress = customerInvoicingInfos?.invoicingAddresses?.[currentSupervisorInvoicingInfosId];

            const nextSupervisorInvoicingInfosId = customerInvoicingInfos?.invoicingAddressAndUserRelationships?.addressIdByUserId?.[listOfUser?.find(user => user?.healthcarePartyId === nextSupervisorHcp?.id)?.id!!];
            const nextSupervisorInvoicingInfos: InvoicingAddress = customerInvoicingInfos?.invoicingAddresses?.[nextSupervisorInvoicingInfosId];

            listOfUserByGroupId.push({
              groupId: group.id,
              firstName: assitantHcp?.firstName || '',
              lastName:assitantHcp?.lastName || '',
              inami: assitantHcp?.nihii || '',
              ssin: assitantHcp?.ssin || '',
              email: user?.email,
              supervisorName:  currentSupervisorHcp ? currentSupervisorHcp?.firstName + ' ' + currentSupervisorHcp?.lastName : '',
              currentSupervisorInvoicingCity: currentSupervisorInvoicingInfos?.city || '',
              currentSupervisorCountry: currentSupervisorInvoicingInfos?.country || '',
              currentSupervisorDataSource: currentSupervisorInvoicingInfos?.dataSource || '',
              currentSupervisorEmailAddress: currentSupervisorInvoicingInfos?.emailAddress || '',
              currentSupervisorExternalId: currentSupervisorInvoicingInfos?.externalId || '',
              currentSupervisorName: currentSupervisorInvoicingInfos?.name || '',
              currentSupervisorPhoneNumber: currentSupervisorInvoicingInfos?.phoneNumber || '',
              currentSupervisorPostalCode: currentSupervisorInvoicingInfos?.postalCode || '',
              currentSupervisorStreet: currentSupervisorInvoicingInfos?.street || '',
              currentSupervisorVat: currentSupervisorInvoicingInfos?.vat || '',
              currentSupervisorModifiedOn: currentSupervisorInvoicingInfos?.modifiedOn ? (() => {
                const date = new Date(currentSupervisorInvoicingInfos.modifiedOn);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
              })() : '',
              assistantValidationDate: assistantInfos?.validationDate || '-',
              nextStatus: assistantInfos?.nextStatus ||'-',
              isNextStructureTheSame: assistantInfos?.isNextStructureTheSame || '-',
              nextSupervisorName: nextSupervisorHcp ? nextSupervisorHcp?.firstName + ' ' + nextSupervisorHcp?.lastName : '',
              nextSupervisorNihii: nextSupervisorHcp ? nextSupervisorHcp?.nihii : '',
              nextSupervisorInvoicingCity: nextSupervisorInvoicingInfos?.city || '',
              nextSupervisorCountry: nextSupervisorInvoicingInfos?.country || '',
              nextSupervisorDataSource: nextSupervisorInvoicingInfos?.dataSource || '',
              nextSupervisorEmailAddress: nextSupervisorInvoicingInfos?.emailAddress || '',
              nextSupervisorExternalId: nextSupervisorInvoicingInfos?.externalId || '',
              nextSupervisorPhoneNumber: nextSupervisorInvoicingInfos?.phoneNumber || '',
              nextSupervisorPostalCode: nextSupervisorInvoicingInfos?.postalCode || '',
              nextSupervisorStreet: nextSupervisorInvoicingInfos?.street || '',
              nextSupervisorVat: nextSupervisorInvoicingInfos?.vat || '',
              nextSupervisorModifiedOn: nextSupervisorInvoicingInfos?.modifiedOn ? (() => {
                const date = new Date(nextSupervisorInvoicingInfos.modifiedOn);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
              })() : ''
            });
          }
        }
        console.log(cpt+'/'+listOfGroups.length);
      }

      var worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(listOfUserByGroupId);
      const workbook = XLSX.utils.book_new();
      const sheetName: string = `${new Date().getTime().toString()}_getAssitantInvoicingValidation.xlsx`;
      XLSX.utils.book_append_sheet(workbook, worksheet, "getAssitantInvoicingValidation");
      XLSX.writeFile(workbook, sheetName);
    }

    private async getActiveDbFromDate(){
      let cpt = 0;
      const listOfGroups = this.localSettingsService.listOfGroups?.length ? this.localSettingsService.listOfGroups : await this.groupService.getAllGroups();
      const activeGroupsResp = await this.desktopService.listGroups();
      const activeGroups = activeGroupsResp?.status?.status === 200 ? activeGroupsResp?.content : [];
      const inactiveGroups = listOfGroups?.filter(group => !activeGroups?.includes(group.id!!));
      const logs: any[] = [];
      for(let group of inactiveGroups){
        cpt++;
        const accessLogs: AccessLog[] = await this.accessLogService.getAccessLogByGroupId(group?.id!!, 600, `${new Date().getFullYear()}-01-01`, `${new Date().getFullYear()}-12-31`);
        const sortedAccessLogs : AccessLog[] = accessLogs?.sort((a, b) => b?.modified!! - a?.modified!!);
          const lastLog: AccessLog = sortedAccessLogs?.[0];
          logs.push({
          groupId: group?.id,
          created: lastLog?.created ? `${new Date(lastLog?.created).getDate().toString()}/${(new Date(lastLog?.created).getMonth() + 1).toString()}/${new Date(lastLog?.created).getFullYear().toString()}`: '-',
          modified: lastLog?.modified ? `${new Date(lastLog?.modified).getDate().toString()}/${(new Date(lastLog?.modified).getMonth() + 1).toString()}/${new Date(lastLog?.modified).getFullYear().toString()}` : '-',
          date: lastLog?.date ? `${new Date(lastLog?.date).getDate().toString()}/${(new Date(lastLog?.date).getMonth() + 1).toString()}/${new Date(lastLog?.date).getFullYear().toString()}` : '-',
          accessType: lastLog?.accessType || '-'
        })
        console.log(cpt+'/'+inactiveGroups.length);
      }

        var worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(logs);
        const workbook = XLSX.utils.book_new();
        const sheetName: string = `${new Date().getTime().toString()}_getActiveDbFromDate.xlsx`;
        XLSX.utils.book_append_sheet(workbook, worksheet, "getActiveDbFromDate");
        XLSX.writeFile(workbook, sheetName);
    }

    private async getConfigVersionByGroupId(){
      let cpt = 0;
      const activeGroupsResp = await this.desktopService.listGroups();
      const activeGroups = activeGroupsResp?.status?.status === 200 ? activeGroupsResp?.content : [];
      const listOfConfigByGroupId: any[] = [];
      for(let group of activeGroups){
        const desktopInfos = await this.desktopService.getGroupConfig(group).catch(e => e);
        cpt++;
        desktopInfos?.content?.configs?.map((config: any) => {
          const updateDate: Date = new Date(config?.updatedAt)
          const updateDateDay = String(updateDate.getDate()).padStart(2, '0');
          const updateDateMonth = String(updateDate.getMonth() + 1).padStart(2, '0');
          const updateDateYear = updateDate.getFullYear();

          const splitedConfigId = config?.configId?.split('|');
          listOfConfigByGroupId.push({
            updateDate: `${updateDateDay}/${updateDateMonth}/${updateDateYear}`,
            groupId: group,
            configIdName: splitedConfigId?.[0],
            configIdUser: splitedConfigId?.[1]?.toLowerCase().replace(/[^a-z0-9]/g, ''),
            currentVersion: config?.currentVersion,
            couchDBVersion: config?.couchDBVersion,
            electronVersion: config?.electronVersion,
            installationType: config?.installationType,
            isKrakenInitialized: config?.isKrakenInitialized === true ? 'Indexation terminée' : config?.isKrakenInitialized === false && config?.installationType !== 'client' ? 'Indexation non terminée, connexion au cloud uniquement' : config?.isKrakenInitialized !== true && config?.isKrakenInitialized !== false ? 'Information non disponible' : config?.installationType === 'client' ? 'Installation de type client' : '',
            isMauriceInitialized: config?.isMauriceInitialized === true ? 'Indexation terminée' : config?.isMauriceInitialized === false && config?.installationType !== 'client' ? 'Indexation non terminée, connexion au cloud uniquement' : config?.isMauriceInitialized !== true && config?.isMauriceInitialized !== false ? 'Information non disponible' : config?.installationType === 'client' ? 'Installation de type client' : '',
          })
        })
        console.log(cpt+'/'+activeGroups.length);
      }

      var worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(listOfConfigByGroupId);
      const workbook = XLSX.utils.book_new();
      const sheetName: string = `${new Date().getTime().toString()}_getConfigVersionByGroupId.xlsx`;
      XLSX.utils.book_append_sheet(workbook, worksheet, "getConfigVersionByGroupId");
      XLSX.writeFile(workbook, sheetName);
    }
}
