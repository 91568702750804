<div class="header">
  <div class="header-title-container">
    Id du groupe: {{selectedGroup?.id}}
  </div>
  <div class="header-btn-container">
    <button mat-icon-button title="" (click)="goToGroups()">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</div>
<div class="group-info-widgets">
  <mat-grid-list class="mg-top-10" cols="6" rowHeight="5:1">
    <mat-grid-tile>
      <div id="widget-all-user" class="dashboard-bloc">
        <div class="dashboard-bloc-content">
          <div class="bloc-container">
            <div class="bloc-container-icon">
              <mat-icon class="dashboard-icon">supervised_user_circle</mat-icon>
            </div>
            <div class="bloc-container-number">
              {{widgets?.allUsers}}
            </div>
          </div>
          <div class="bloc-bottom-title-container">
            Nombre total d'utilisateurs
          </div>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div id="widget-active-user" class="dashboard-bloc">
        <div class="dashboard-bloc-content">
          <div class="bloc-container">
            <div class="bloc-container-icon">
              <mat-icon class="dashboard-icon">notifications</mat-icon>
            </div>
            <div class="bloc-container-number">
              {{widgets?.activeUsers}}
            </div>
          </div>
          <div class="bloc-bottom-title-container">
            Nombre d'utilisateurs actifs
          </div>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div id="widget-desactivated-user" class="dashboard-bloc">
        <div class="dashboard-bloc-content">
          <div class="bloc-container">
            <div class="bloc-container-icon">
              <mat-icon class="dashboard-icon">notifications_off</mat-icon>
            </div>
            <div class="bloc-container-number">
              {{widgets?.inactiveUsers}}
            </div>
          </div>
          <div class="bloc-bottom-title-container">
            Nombre d'utilisateurs désactivés
          </div>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DATABASE, ModuleTypeEnum.DATABASE_GROUP, SubModuleTypeEnum.DATABASE_GROUP_INFO_USER_DIARY)">
      <div id="widget-diary-user" class="dashboard-bloc">
        <div class="dashboard-bloc-content">
          <div class="bloc-container">
            <div class="bloc-container-icon">
              <mat-icon class="dashboard-icon">date_range</mat-icon>
            </div>
            <div class="bloc-container-number">
              {{widgets?.agendaUsers}}
            </div>
          </div>
          <div class="bloc-bottom-title-container">
            Nombre de patients agenda en ligne
          </div>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div id="widget-v8-user" class="dashboard-bloc">
        <div class="dashboard-bloc-content">
          <div class="bloc-container">
            <div class="bloc-container-icon">

            </div>
            <div class="bloc-container-number">
              <ng-container *ngIf="widgets?.isV8Activated === true">V8</ng-container>
              <ng-container *ngIf="widgets?.isV8Activated === false">V5</ng-container>
            </div>
          </div>
          <div class="bloc-bottom-title-container">
            Version de l'API
          </div>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
<div class="tab-group-container">
  <mat-tab-group>
    <mat-tab label="Utilisateurs" *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DATABASE, ModuleTypeEnum.DATABASE_GROUP, SubModuleTypeEnum.DATABASE_GROUP_INFO_USER)">
      <ms-database-group-info-user [listOfUsers]="listOfUsers" [listOfHcps]="listOfHcps" [groupInfos]="selectedGroup" *ngIf="!isLoading" [versionType]="versionType"></ms-database-group-info-user>
    </mat-tab>
    <mat-tab label="Logs">
      <ms-database-group-info-logs [listOfUsers]="listOfUsers" [listOfHcps]="listOfHcps" *ngIf="!isLoading" [groupId]="groupId"></ms-database-group-info-logs>
    </mat-tab>
    <mat-tab label="Infos matériel" *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DATABASE, ModuleTypeEnum.DATABASE_GROUP, SubModuleTypeEnum.DATABASE_GROUP_INFO_HARDWARE)">
      <ms-database-group-info-hardware [desktopInfos]="desktopInfos" *ngIf="!isLoading"></ms-database-group-info-hardware>
    </mat-tab>
    <!--<mat-tab label="Infos logiciel" *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DATABASE, ModuleTypeEnum.DATABASE_GROUP, SubModuleTypeEnum.DATABASE_GROUP_INFO_SOFTWARE)">
      <ms-database-group-info-software *ngIf="!isLoading"></ms-database-group-info-software>
    </mat-tab>-->
    <mat-tab label="App settings">
      <ms-database-group-info-app-settings [appSettings]="appSettings" *ngIf="!isLoading"></ms-database-group-info-app-settings>
    </mat-tab>
    <mat-tab label="Infos db cloud" *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DATABASE, ModuleTypeEnum.DATABASE_GROUP, SubModuleTypeEnum.DATABASE_GROUP_INFO_DATABASE)">
      <ms-database-group-info-database [groupStorageInfos]="groupStorageInfos" [selectedGroup]="selectedGroup" *ngIf="!isLoading"></ms-database-group-info-database>
    </mat-tab>
  </mat-tab-group>
</div>
<ms-activity-indicator-with-messages [fullWidth]="true" [isLoading]="isLoading" [overlay]="true" [messages]="messages"></ms-activity-indicator-with-messages>



